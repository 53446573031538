import style from './index.module.scss'
import { Image, Swiper, Toast, NoticeBar, Tabs, ErrorBlock, Popup } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'
import { useNavigate } from 'react-router-dom'
import axios from '../../../../util/axios'
import React, { useState, useEffect } from 'react'
import { copyText, getDateformat } from '../../../../util/utils'
import _ from 'lodash'
function Index (props) {
    const navigate = useNavigate()
    let style_type = props.info.shop_info.style_type // 版本样式控制
    let [state, setState] = useState({
        goodsList: []
    })
    let [category, setCategory] = useState({
        categoryList: [],
        cat_id: ''
    })
    let [coupon, setCoupon] = useState({
        couponList: []
    })

    const [visible, setVisible] = useState(false)
    let [terraceList, setTerraceList] = useState([
        //{
        //  name: '美团超市',
        //  icon: require('../../../../image/icon/icon1.png'),
        //  path: '/JuTwitter/Page/MeiTuanSupermarket'
        //},
        {
            name: '旅游门票',
            icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/Tengchain/food3rd/index/btn_ticket.png',
            path: '/JuTwitter/Page/AttractionTickets',
            we_app_info: 1
        },
        {
            name: '话费充值',
            icon: require('../../../../image/huafei.png'),
            path: '/JuTwitter/Page/TelephoneBillUsers',
            we_app_info: 1
        },
        {
            name: '电费充值',
            icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/ElectricityBillUsers.png',
            path: '/JuTwitter/Page/ElectricityBillUsers'
        },
        //{
        //  name: '高德打车',
        //  icon: require('../../../../image/icon/icon2.png'),
        //  path: '/JuTwitter/Page/GaoDeCar'
        //},
        //{
        //  name: '哈啰打车',
        //  icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/haluo.png',
        //  path: '/JuTwitter/Page/GaoDeCar'
        //},
        {
            name: '滴滴货运',
            icon: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/transport.png',
            path: '/JuTwitter/Page/GaoDeCar'
        },
        {
            name: 'T3出行',
            icon: require('../../../../image/icon/icon6.png'),
            path: '/JuTwitter/Page/T3Car'
        },
        {
            name: '安心购票',
            icon: require('../../../../image/goupiao1.png'),
            path: '/JuTwitter/Page/BillUsers',
            title: '飞机票'
        },
        {
            name: '火车票',
            icon: require('../../../../image/icon/icon7.png'),
            path: '/JuTwitter/Page/TrainCar',
            title: '加速包'
        },
        {
            name: '影院有约',
            icon: require('../../../../image/yingyuan.png'),
            path: '/JuTwitter/Page/MovieUsers'
        },
        {
            name: '美团酒店',
            icon: require('../../../../image/icon/icon5.png'),
            path: '/JuTwitter/Page/MeiTuanWineshop',
            we_app_info: 1
        },
        {
            name: '惠订酒店',
            icon: require('../../../../image/jiudian.png'),
            path: '/JuTwitter/Page/HotelUsers',
            we_app_info: 1
        },
        //{
        //  name: '滴滴租车',
        //  icon: require('../../../../image/didi.png'),
        //  path: '/JuTwitter/Page/DiDiCarRentalUsers'
        //},
        //{
        //    name: '寄快递',
        //    icon: require('../../../../image/kuaidi.png'),
        //    path: '/JuTwitter/Page/ExpressUsers'
        //},
        {
            name: '抖音好物',
            icon: require('../../../../image/icon/icon3.png'),
            path: '/JuTwitter/Page/DouyinGoods'
        },
        {
            name: '拼多多',
            icon: require('../../../../image/icon/icon4.png'),
            path: '/JuTwitter/Page/PinDuoDuoGoods'
        },
        {
            name: '油惠共享',
            icon: require('../../../../image/you.png'),
            path: '/JuTwitter/Page/oilUsers',
            title: '加油'
        },
        {
            name: '种草社区',
            icon: require('../../../../image/icon24.png'),
            path: '/community',
            title: '去种草'
        },
        {
            name: '分享',
            icon: require('../../../../image/share.png')
        }
    ])
    // type 1、直接跳外部链接 2、需要调接口跳链接 3、直接跳路由 4、指定调接口跳链接 5、需要调接口并且传id跳链接

    let jumpPage = (path, type, id) => {
        if (type == 1) {
            window.location.href = path
        }
        if (type == 2) {
            if (path == '/community') return navigate(path)
            axios.post(path).then((res) => {
                if (res.code == 0) {
                    let { result } = res
                    if (result.h5) {
                        window.location.href = result.h5
                    } else {
                        window.location.href = result
                    }
                } else {
                    Toast.show({
                        content: res.msg
                    })
                }
            })
        }
        if (type == 3) {
            navigate(path)
        }
        if (type == 4) {
            axios
                .post('/JuTwitter/Page/actLinkUser', {
                    act_link_id: id
                })
                .then((res) => {
                    if (res.code === 0) {
                        window.location.href = res.result
                    } else {
                        Toast.show({
                            content: res.msg
                        })
                    }
                })
        }
        if (type == 5) {
            axios
                .post(path, {
                    act_link_id: id
                })
                .then((res) => {
                    if (res.code == 0) {
                        window.location.href = res.result
                    } else {
                        Toast.show({
                            content: res.msg
                        })
                    }
                })
        }
    }
    let getGoodsList = (cat_id = '') => {
        axios
            .post('/JuTwitter/CompanyGoods/list', {
                page: 1,
                rows: 1000,
                cat_id: cat_id
            })
            .then((res) => {
                if (res.code == 0) {
                    let _state = _.cloneDeep(state)
                    let list = res.result.list
                    for (let i in list) {
                        list[i].head_imgs = JSON.parse(list[i].head_imgs)
                    }
                    _state.goodsList = list
                    setState(_state)
                }
            })
    }
    let getTerraceAppid = async () => {
        let _terraceList = _.cloneDeep(terraceList)

        if (Array.isArray(props.info.jump_private)) {
            _terraceList.pop()
        } else {
            _terraceList[_terraceList.length - 1].wxapp_info = {
                original_id: props.info.jump_private.original_id,
                we_app_info: {
                    page_path: props.info.jump_private.path
                }
            }
        }
        let arr = _terraceList.map(item => {
            return item.we_app_info ? (axios.post(item.path).then((res) => {
                if (res.code == 0) {
                    item.wxapp_info = res.result
                }
            })) : false
        });
        await Promise.all(arr.filter(v => v));
        console.log(_terraceList)
        setTerraceList(_terraceList)
    }
    let getCategoryList = () => {
        axios.post('/JuTwitter/CompanyGoods/catsList', { level: 1 }).then((res) => {
            if (res.code == 0) {
                let _category = _.cloneDeep(category)
                let categoryList = res.result
                categoryList.unshift({
                    first_cat_id: '',
                    first_cat_name: '全部'
                })
                _category.categoryList = categoryList
                setCategory(_category)
            }
        })
    }
    let changeCategory = (key) => {
        setCategory((prev) => {
            return {
                categoryList: prev.categoryList,
                cat_id: key
            }
        })
        getGoodsList(key)
    }
    let getCouponList = () => {
        axios
            .post('/JuTwitter/Coupon/list', {
                page: 1,
                rows: 1000
            })
            .then((res) => {
                if (res.code == 0) {
                    let _coupon = _.cloneDeep(coupon)
                    let couponList = res.result.list
                    _coupon.couponList = [...couponList]
                    setCoupon(_coupon)
                }
            })
    }
    const toRem = ($a) => {
        return $a / 37.5 + 'rem'
    }

    let handleCoupon = (item) => {
        if (item.my_get_number < item.each_limit) {
            axios
                .post('/JuTwitter/Coupon/getCoupon', {
                    id: item.id
                })
                .then((res) => {
                    if (res.code == 0) {
                        Toast.show({
                            content: '领取成功'
                        })
                        getCouponList()
                    } else {
                        Toast.show({
                            content: res.msg
                        })
                    }
                })
        }
    }
    useEffect(() => {
        getCategoryList()
        getGoodsList()
        getTerraceAppid()
        getCouponList()
    }, [])
    return (
        <>
            <div
                className={style.content}
                onClick={(e) => {
                    if (props.info.shop_info.douyin_code) copyText(e, props.info.shop_info.douyin_code)
                }}
            >
                <div className={style.header}>
                    <div className={style.title}>
                        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img10.png" />
                        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img11.png" />
                        {props.info.company_id ? (
                            <>
                                <div className="flex1"></div>
                                <div className={style.myshop} onClick={() => navigate('/enterpriseShop', { state: props.info.real_company_id })}>
                                    <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/myshop.png" alt="" className={style.myshop_icon} />
                                    <div>进店逛逛</div>
                                </div>
                            </>
                        ) : null}
                    </div>
                    {
                        props.info.shop_info.shop_vr_banners.length > 0 ? <div className={style.banner}>
                            {props.info.shop_info.shop_vr_banners.length > 1 ? (
                                <Swiper loop>
                                    {props.info.shop_info.shop_vr_banners &&
                                        props.info.shop_info.shop_vr_banners.map((item, index) => {
                                            return (
                                                <Swiper.Item key={index}>
                                                    <div className={style.img}>
                                                        {/*{item.changetype == 2 ? (
                            <wx-open-launch-weapp id="launch-btn" username={item.original_id} path={item.page_path} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                              <script type="text/wxtag-template">
                                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                              </script>
                            </wx-open-launch-weapp>
                          ) : null}*/}
                                                        <Image src={item.img} fit="cover" onClick={() => jumpPage(item.link, 1)}></Image>
                                                    </div>
                                                </Swiper.Item>
                                            )
                                        })}
                                </Swiper>
                            ) : (
                                <Swiper loop indicator={() => null}>
                                    {props.info.shop_info.shop_vr_banners &&
                                        props.info.shop_info.shop_vr_banners.map((item, index) => {
                                            return (
                                                <Swiper.Item key={index}>
                                                    <div className={style.img}>
                                                        {/*{item.changetype == 2 ? (
                            <wx-open-launch-weapp id="launch-btn" username={item.original_id} path={item.page_path} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                              <script type="text/wxtag-template">
                                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                              </script>
                            </wx-open-launch-weapp>
                          ) : null}*/}
                                                        <Image src={item.img} fit="cover" onClick={() => jumpPage(item.link, 1)}></Image>
                                                    </div>
                                                </Swiper.Item>
                                            )
                                        })}
                                </Swiper>
                            )}
                        </div> : null
                    }
                    <div className={style.notice}>
                        <div className={style.left}>
                            <div>全真</div>
                            <div style={{ backgroundImage: 'url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img48.png)' }}>通告</div>
                        </div>
                        <div className={style.right}>
                            <NoticeBar
                                icon=""
                                style={{ '--background-color': '#fff', '--text-color': '#222', '--border-color': 0, padding: 0 }}
                                content={`您是第${props.info.client_num}位顾客，${props.info.shop_info.store_name}欢迎您~`}
                            />
                        </div>
                    </div>
                </div>
                <div className={style.center}>
                    {style_type == 7 ? (
                        //无同城优惠  到店支付 + wifi + 点餐 + 生活服务
                        <div className={style.goApplets2}>
                            <div className={style.left} style={{ backgroundImage: 'url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img35.png)' }} onClick={() => navigate('/pay')}></div>
                            <div className={style.right}>
                                <div style={{ backgroundImage: 'url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img28.png)' }}>
                                    <wx-open-launch-weapp
                                        id="launch-btn"
                                        username={props.info.wifi_appid.original_id}
                                        path={props.info.wifi_appid.path}
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                    >
                                        <script type="text/wxtag-template">
                                            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                                        </script>
                                    </wx-open-launch-weapp>
                                </div>
                                <div style={{ backgroundImage: 'url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img36.png)' }}>
                                    <wx-open-launch-weapp
                                        id="launch-btn"
                                        username={props.info.scan_code_appid.original_id}
                                        path={props.info.scan_code_appid.path}
                                        style={{ position: 'absolute', zIndex: 10, top: 0, left: 0, width: '100%', height: '100%' }}
                                    >
                                        <script type="text/wxtag-template">
                                            <div style={{ position: 'absolute', zIndex: 10, top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                                        </script>
                                    </wx-open-launch-weapp>
                                </div>
                                <div style={{ backgroundImage: 'url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img100.png)' }} onClick={() => jumpPage('/life', 3)}></div>
                            </div>
                        </div>
                    ) : [10, 11, 12, 13, 14, 15, 16].includes(style_type) ? (
                        // 点餐 + 生活服务
                        <div className={style.goApplets3}>
                            {style_type != 16 ? (
                                <>
                                    <div
                                        style={{
                                            backgroundImage: `url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img${style_type == 10 || style_type == 11 ? 40 : style_type == 12 || style_type == 13 ? 42 : style_type == 14 || style_type == 15 ? 41 : ''
                                                }.png)`
                                        }}
                                        onClick={() => {
                                            if (style_type > 13) navigate('/pay')
                                        }}
                                    >
                                        {style_type == 10 || style_type == 11 ? (
                                            <wx-open-launch-weapp
                                                id="launch-btn"
                                                username={props.info.scan_code_appid.original_id}
                                                path={props.info.scan_code_appid.path}
                                                style={{ position: 'absolute', zIndex: 10, top: 0, left: 0, width: '100%', height: '100%' }}
                                            >
                                                <script type="text/wxtag-template">
                                                    <div style={{ position: 'absolute', zIndex: 10, top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                                                </script>
                                            </wx-open-launch-weapp>
                                        ) : style_type == 12 || style_type == 13 ? (
                                            <wx-open-launch-weapp
                                                id="launch-btn"
                                                username={props.info.wifi_appid.original_id}
                                                path={props.info.wifi_appid.path}
                                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                            >
                                                <script type="text/wxtag-template">
                                                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                                                </script>
                                            </wx-open-launch-weapp>
                                        ) : null}
                                    </div>
                                    <div style={{ backgroundImage: `url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img100.png)` }} onClick={() => jumpPage('/life', 3)}></div>
                                </>
                            ) : null}
                        </div>
                    ) : (
                        // wifi + 点餐 + 美团券包
                        <div className={style.goApplets}>
                            {[1, 3, 4, 7, 8, 9].includes(style_type) ? (
                                <div style={{ backgroundImage: 'url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img59.png)' }}>
                                    <wx-open-launch-weapp
                                        id="launch-btn"
                                        username={props.info.wifi_appid.original_id}
                                        path={props.info.wifi_appid.path}
                                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                    >
                                        <script type="text/wxtag-template">
                                            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                                        </script>
                                    </wx-open-launch-weapp>
                                </div>
                            ) : (
                                <div style={{ backgroundImage: 'url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img32.png)' }} onClick={() => navigate('/pay')}></div>
                            )}
                            {style_type == 8 || style_type == 9 ? (
                                <div style={{ backgroundImage: 'url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img33.png)' }} onClick={() => navigate('/pay')}></div>
                            ) : (
                                <div style={{ backgroundImage: 'url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img45.png)' }}>
                                    <wx-open-launch-weapp
                                        id="launch-btn"
                                        username={props.info.scan_code_appid.original_id}
                                        path={props.info.scan_code_appid.path}
                                        style={{ position: 'absolute', zIndex: 10, top: 0, left: 0, width: '100%', height: '100%' }}
                                    >
                                        <script type="text/wxtag-template">
                                            <div style={{ position: 'absolute', zIndex: 10, top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                                        </script>
                                    </wx-open-launch-weapp>
                                </div>
                            )}

                            <div
                                style={{ backgroundImage: 'url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/MeiTuanCoupon.png)' }}
                                onClick={() => jumpPage('/JuTwitter/Page/MeiTuanCoupon', 2)}
                            ></div>
                        </div>
                    )}
                    <div className={style.terraceList}>
                        <div className={style.top}>
                            <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img57.png" onClick={() => jumpPage('/JuTwitter/Page/HuaPigUsers', 2)} />
                            <img
                                src={`https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img${props.info.is_tencent ? 61 : 58}.png`}
                                onClick={() => jumpPage(props.info.is_tencent ? '/JuTwitter/Page/JDDog' : '/JuTwitter/Page/EleBook', 2)}
                            />
                            <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img55.png" onClick={() => jumpPage('/JuTwitter/Page/DiDiCar', 2)} />
                            <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img56.png" onClick={() => jumpPage('/JuTwitter/Page/MeiTuanBook', 2)} />
                        </div>
                        <div className={style.bottom}>
                            {terraceList.map((item, index) => {
                                return (
                                    <div className={style.item} key={index} onClick={() => (!item.wxapp_info ? jumpPage(item.path, item.type ? item.type : 2) : null)}>
                                        {item.wxapp_info ? (
                                            <wx-open-launch-weapp
                                                id="launch-btn"
                                                username={item.wxapp_info.original_id}
                                                path={item.wxapp_info.we_app_info.page_path}
                                                style={{ position: 'absolute', zIndex: 10, top: 0, left: 0, width: '100%', height: '100%' }}
                                            >
                                                <script type="text/wxtag-template">
                                                    <div style={{ position: 'absolute', zIndex: 10, top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                                                </script>
                                            </wx-open-launch-weapp>
                                        ) : null}
                                        <Image src={item.icon} />
                                        <div>{item.name}</div>
                                        {item.title ? <div className={style.title}>{item.title}</div> : null}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {style_type == 1 ? (
                        <div className={style['pay-local']}>
                            <div className={style.pay} style={{ backgroundImage: 'url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img44.png)' }} onClick={() => navigate('/pay')}></div>
                            <div className={style.local} onClick={() => jumpPage(props.info.bottom[0].link, 1)}>
                                <div className={style.left}>
                                    <div>为你推荐</div>
                                    <div>同城优惠</div>
                                    <div>
                                        <span>去逛逛</span>
                                        <span>GO</span>
                                    </div>
                                </div>
                                <div className={style.right}>
                                    <Image fit="cover" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img21.gif" />
                                </div>
                                <div className={style.right}>
                                    <Image fit="cover" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img22.gif" />
                                </div>
                                <div className={style.right}>
                                    <Image fit="cover" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img23.gif" />
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {[3, 5, 8, 10, 12, 14, 16].includes(style_type) ? (
                        <div className={style.locals} onClick={() => jumpPage(props.info.bottom[0].link, 1)}>
                            <div className={style.left}>
                                <div>为你推荐</div>
                                <div>同城优惠</div>
                                <div>
                                    <span>去逛逛</span>
                                    <span>GO</span>
                                </div>
                            </div>
                            <div className={style.right}>
                                <Image fit="cover" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img21.gif" />
                            </div>
                            <div className={style.right}>
                                <Image fit="cover" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img22.gif" />
                            </div>
                            <div className={style.right}>
                                <Image fit="cover" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img23.gif" />
                            </div>
                        </div>
                    ) : null}

                    <div className={style.service_coupon}>
                        <div className={style.service}>
                            {Array.isArray(props.info.jump_private) ? (
                                <div className={style.title}>
                                    <div onClick={() => jumpPage('/life', 3)}>
                                        生活服务
                                        <RightOutline color="#222" fontSize={14} fontWeight={700} />
                                    </div>
                                    <div>时时享受，样样精彩~</div>
                                </div>
                            ) : (
                                <div className={style.title}>
                                    <div>
                                        生活服务
                                        <RightOutline color="#222" fontSize={14} fontWeight={700} />
                                    </div>
                                    <div>时时享受，样样精彩~</div>
                                    <wx-open-launch-weapp
                                        id="launch-btn"
                                        username={props.info.jump_private.original_id}
                                        path={props.info.jump_private.path}
                                        style={{ position: 'absolute', zIndex: 10, top: 0, left: 0, width: '100%', height: '100%' }}
                                    >
                                        <script type="text/wxtag-template">
                                            <div style={{ position: 'absolute', zIndex: 10, top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                                        </script>
                                    </wx-open-launch-weapp>
                                </div>
                            )}
                            <div className={style.drink}>
                                {props.info.new_chain_restaurant.map((item, index) => {
                                    return (
                                        <div className={style.item} key={index} onClick={() => jumpPage(item.link, 1)}>
                                            <Image src={item.icon}></Image>
                                            <div className={style.name}>{item.act_name}</div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={style.take}>
                                <div className={style.item} onClick={() => jumpPage(null, 4, props.info.display_window[3].id)}>
                                    <Image src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img16.png" />
                                    <div className={style.name}>
                                        <p>打车出行</p>
                                        <p>出行随叫随到</p>
                                    </div>
                                </div>
                                <div className={style.item} onClick={() => jumpPage(null, 4, props.info.display_window[2].id)}>
                                    <Image src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img17.png" />
                                    <div className={style.name}>
                                        <p>外卖点餐</p>
                                        <p>美食天天神券</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style.coupon_goods} style={{ backgroundImage: 'url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img60.png)' }}>
                            <div className={style.title}>
                                <div
                                    onClick={() => {
                                        localStorage.setItem('source', 'jd')
                                        localStorage.setItem('keyword', '')
                                        jumpPage('/shopping', 3)
                                    }}
                                >
                                    <div>
                                        优惠购
                                        <RightOutline color="#FF485E" fontSize={14} fontWeight={700} />
                                    </div>
                                    {/* <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img52.png" /> */}
                                </div>
                                <div>好品质，太好逛了吧~</div>
                            </div>
                            <div className={style.goods} style={{ backgroundImage: 'url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img7.png)' }}>
                                {props.info.goods_list.slice(0, 2).map((item, index) => {
                                    return (
                                        <div className={style.item} key={index} onClick={() => jumpPage('/shopping', 3)}>
                                            <Image src={item.goods_list.goods_img} fit="cover" />
                                            <div className={style.price}>￥{item.goods_list.goods_price}</div>
                                            <div className={style.zhekou}>
                                                <div>{Math.floor(Math.random() * 9) + 1}折</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div
                                className={style.coupon}
                                style={{ backgroundImage: 'url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img7.png)' }}
                                onClick={() => jumpPage(props.info.bottom[2].link, 1)}
                            >
                                <div className={style.item}>
                                    <div>
                                        <span>￥</span>
                                        <span>20</span>
                                    </div>
                                    <div>满100可用</div>
                                    <div style={{ backgroundImage: 'url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img27.png)' }}>去领取</div>
                                </div>
                                <div className={style.item}>
                                    <div>
                                        <span>￥</span>
                                        <span>30</span>
                                    </div>
                                    <div>满200可用</div>
                                    <div style={{ backgroundImage: 'url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/microcode/template1/img27.png)' }}>去领取</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.info.company_id ? (
                        <div className={style.goods_list}>
                            <div className={style.title}>
                                <img src={require('../../../../image/icon25.png')} />
                                <span>惠购好物</span>
                                <img src={require('../../../../image/icon26.png')} />
                            </div>
                            {/* 优惠券 */}
                            {coupon.couponList.length ? (
                                <div
                                    className={style.c_coupon}
                                    onClick={() => {
                                        setVisible(true)
                                    }}
                                >
                                    {coupon.couponList.map((item, index) => (
                                        <React.Fragment key={index}>
                                            {index > 0 ? <div className={style.c_coupon_line}></div> : null}
                                            <div className={style.c_coupon_text}>
                                                {item.type == 1 ? item.consume_full * 1 + '减' + item.subtract * 1 : item.type == 2 ? '立减' + item.subtract * 1 : item.consume_full * 1 + '打' + item.discount + '折'}
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                            ) : null}
                            <Popup
                                visible={visible}
                                showCloseButton
                                onMaskClick={() => {
                                    setVisible(false)
                                }}
                                onClose={() => {
                                    setVisible(false)
                                }}
                                bodyStyle={{ borderRadius: `${toRem(16)} ${toRem(16)} 0 0` }}
                            >
                                <div style={{ padding: `${toRem(25)} ${toRem(14)}` }}>
                                    <div style={{ fontSize: toRem(18) }}>优惠券</div>
                                    <div style={{ height: toRem(236), marginTop: toRem(20), overflowY: 'auto' }}>
                                        {coupon.couponList.map((item, index) => (
                                            <div key={index} style={{ display: 'flex', marginBottom: toRem(13), height: toRem(70), opacity: item.my_get_number < item.each_limit ? '1' : '0.5' }}>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        height: toRem(70),
                                                        borderRadius: `${toRem(5)} 0px 0px ${toRem(5)}`,
                                                        border: `${toRem(1)} dashed #FB3C49`,
                                                        borderRight: 'none',
                                                        boxSizing: 'border-box',
                                                        padding: toRem(6)
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        {item.type == 3 ? (
                                                            <div
                                                                style={{
                                                                    fontSize: toRem(31),
                                                                    color: '#FB3C49'
                                                                }}
                                                            >
                                                                {item.discount}
                                                                <span
                                                                    style={{
                                                                        fontSize: toRem(14)
                                                                    }}
                                                                >
                                                                    折
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    fontSize: toRem(31),
                                                                    color: '#FB3C49'
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontSize: toRem(14)
                                                                    }}
                                                                >
                                                                    ￥
                                                                </span>
                                                                {item.subtract * 1}
                                                            </div>
                                                        )}
                                                        <div style={{ marginLeft: toRem(9) }}>
                                                            <div
                                                                style={{
                                                                    fontSize: toRem(12),
                                                                    color: '#444444'
                                                                }}
                                                            >
                                                                优惠券
                                                            </div>
                                                            <div
                                                                style={{
                                                                    fontSize: toRem(10),
                                                                    color: '#444444'
                                                                }}
                                                            >
                                                                {item.type == 2 ? '立减券' : `满${item.consume_full * 1}可使用`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ fontSize: toRem(10), color: '#999999', marginTop: toRem(5), whiteSpace: 'nowrap' }}>
                                                        有效期至：{item.time_type == 1 ? getDateformat(item.end_time, 'y.m.d') : `领取后${item.days}天内`}
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        width: toRem(50),
                                                        height: toRem(70),
                                                        borderRadius: `0px ${toRem(5)} ${toRem(5)} 0px`,
                                                        backgroundColor: '#FF5A01',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        fontSize: toRem(12),
                                                        color: '#ffffff',
                                                        position: 'relative'
                                                    }}
                                                    onClick={() => handleCoupon(item)}
                                                >
                                                    <div
                                                        style={{
                                                            position: 'relative',
                                                            zIndex: 10
                                                        }}
                                                    >
                                                        {item.my_get_number < item.each_limit ? '领取' : '已领取'}
                                                    </div>
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            left: toRem(-5),
                                                            top: toRem(30),
                                                            width: toRem(10),
                                                            height: toRem(10),
                                                            background: ' #FF5A01',
                                                            borderRadius: '50%'
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Popup>
                            <div className={style.category}>
                                <Tabs
                                    activeKey={category.cat_id}
                                    style={{
                                        '--active-line-height': '0px',
                                        '--title-font-size': '15px',
                                        '--active-title-color': '#FF5A01'
                                    }}
                                    onChange={(key) => changeCategory(key)}
                                >
                                    {category.categoryList.map((item) => (
                                        <Tabs.Tab title={item.first_cat_name} key={item.first_cat_id} />
                                    ))}
                                </Tabs>
                            </div>
                            {state.goodsList.length ? (
                                <div className={style.list}>
                                    <div className={style.left}>
                                        {state.goodsList.map((item, index) => {
                                            return Number(index + 1) % 2 == 1 ? (
                                                <div
                                                    className={style.item}
                                                    key={index}
                                                    onClick={() => {
                                                        navigate('/enterpriseGoodsDetails', { state: item.id })
                                                    }}
                                                >
                                                    <img src={item.head_imgs[0]} alt="" />
                                                    <div className={style.goods_name}>{item.title}</div>
                                                    <div className={style.goods_price}>
                                                        <span>{item.sale_price}</span>
                                                        <span>已售{item.sales}件</span>
                                                    </div>
                                                </div>
                                            ) : null
                                        })}
                                    </div>
                                    <div className={style.right}>
                                        {state.goodsList.map((item, index) => {
                                            return Number(index + 1) % 2 == 0 ? (
                                                <div
                                                    className={style.item}
                                                    key={index}
                                                    onClick={() => {
                                                        navigate('/enterpriseGoodsDetails', { state: item.id })
                                                    }}
                                                >
                                                    <img src={item.head_imgs[0]} alt="" />
                                                    <div className={style.goods_name}>{item.title}</div>
                                                    <div className={style.goods_price}>
                                                        <span>{item.sale_price}</span>
                                                        <span>已售{item.sales}件</span>
                                                    </div>
                                                </div>
                                            ) : null
                                        })}
                                    </div>
                                </div>
                            ) : (
                                <ErrorBlock status="empty" image="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/empty_goods.png"></ErrorBlock>
                            )}
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    )
}

export default Index
