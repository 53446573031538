import './index.scss'
import React, { useState, useEffect } from 'react'
import axios from '../../../util/axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { Input, Toast, Popup } from 'antd-mobile'
import { getDateformat } from '../../../util/utils'
import { uniacid } from '../../../util/config'
function ConfirmGoodsOrder () {
    const location = useLocation()
    const navigate = useNavigate()
    let [state, setState] = useState({
        addressInfo: null,
        message: '',
        shopList: location.state.shopList
    })
    let [makeInfo, setMakeInfo] = useState({
        amount: 0
    })
    let [couponActive, setCouponActive] = useState([])
    let [couponList, setCouponList] = useState([])
    let [couponIndex, setCouponIndex] = useState(0)
    const [visible, setVisible] = useState(false)
    let getDefaultAddress = () => {
        axios
            .post('/JuTwitter/Address/getDefaultAddress', {
                uniacid: uniacid
            })
            .then((res) => {
                if (res.code == 0) {
                    if (res.result) {
                        setState({
                            ...state,
                            addressInfo: res.result
                        })
                        getMakeOrder(res.result.id)
                    } else {
                        getMakeOrder()
                    }
                }
            })
    }
    let getMakeOrder = (address_id) => {
        let data = {
            company_id: location.state.company_id,
            sku_json: location.state.sku_json,
            order_type: 2
        }
        if (address_id) data.address_id = address_id
        axios.post('/JuTwitter/Buy/makeOrder', data).then(async (res) => {
            if (res.code == 0) {
                let info = res.result
                let shopList = state.shopList
                let _couponActive = []
                for (let i in shopList) {
                    for (let y in info.newArray) {
                        if (shopList[i].company_id == y) {
                            let freight_template_fee = 0
                            for (let k in info.newArray[y]) {
                                freight_template_fee = freight_template_fee + info.newArray[y][k].freight_template_fee
                            }
                            shopList[i].freight_template_fee = freight_template_fee
                            shopList[i].amount = parseFloat((shopList[i].goods_amount + freight_template_fee).toFixed(2))
                            let obj = await getDefaultCoupon(shopList[i].goods_amount)
                            _couponActive.push(obj)
                        }
                    }
                }
                setMakeInfo(res.result)
                setCouponActive(_couponActive)
            }
        })
    }
    let submitOrder = () => {
        if (!state.addressInfo) return Toast.show({ content: '请添加地址' })
        axios
            .post('/JuTwitter/Buy/create', {
                sku_json: location.state.sku_json,
                cart_way: location.state.cart_way,
                address_id: state.addressInfo.id,
                order_type: 3,
                company_id: location.state.company_id,
                message: state.message,
                coupon_id: couponActive.map((v) => v.id).toString()
            })
            .then((res) => {
                if (res.code == 0) {
                    return axios.post('/JuTwitter/Buy/pay', {
                        payment_channel: 'eyun_pay',
                        pay_sn: res.result.pay_sn
                    })
                } else {
                    Toast.show({
                        content: res.msg
                    })
                }
            })
            .then((res) => {
                if (res.code == 0) {
                    window.location.href = res.result.payUrl
                } else {
                    Toast.show({
                        content: res.msg
                    })
                }
            })
    }
    let getDefaultCoupon = async (amount) => {
        let res = await axios.post('/JuTwitter/Coupon/defaultCoupon', {
            amount
        })
        if (res.code == 0) {
            return res.result.id ? res.result : { amount: 0 }
        } else {
            Toast.show({
                content: res.msg
            })
            return {
                amount: 0
            }
        }
    }
    let getCouponList = (amount) => {
        axios
            .post('/JuTwitter/Coupon/myCoupon', {
                price: amount
            })
            .then((res) => {
                if (res.code == 0) {
                    setCouponList(res.result)
                } else {
                    Toast.show({
                        content: res.msg
                    })
                }
            })
    }
    const toRem = ($a) => {
        return $a / 37.5 + 'rem'
    }
    let discountprice = (index) => {
        return couponActive.length ? Math.floor(couponActive[index].amount * 100) / 100 : 0
    }
    let totalpart = (item, index) => {
        let price = (item.amount - discountprice(index)).toFixed(2) * 1
        return price > 0 ? price * 1 : 0
    }
    let getTotalAll = () => {
        let price = 0
        state.shopList.forEach((item, index) => {
            price += totalpart(item, index)
        })
        return price
    }

    useEffect(() => {
        document.title = '提交订单'
        getDefaultAddress()
    }, [])
    return (
        <div className="confirm-goods">
            <div
                className="address-info"
                onClick={() => {
                    if (state.addressInfo) {
                        navigate('/myAddress', { state: 1 })
                    } else {
                        navigate('/addAddress')
                    }
                }}
            >
                {state.addressInfo ? (
                    <div className="left">
                        <div>
                            <p>
                                <span>{state.addressInfo.name}</span> <span>{state.addressInfo.mobile}</span>
                            </p>
                            <p>{state.addressInfo.full_name_address}</p>
                        </div>
                    </div>
                ) : (
                    <div className="add">添加收货地址</div>
                )}
                <div className="right">
                    <img src={require('../../../image/pointsMall/icon8.png')} alt="" />
                </div>
            </div>
            {state.shopList.map((item, index) => {
                return (
                    <div key={index}>
                        <div className="order-goods-list">
                            <div className="shop">
                                <img src={item.shop_logo} />
                                <span>{item.shop_name}</span>
                            </div>
                            {item.goodsList.map((goods, y) => {
                                return (
                                    <div className="item" key={y}>
                                        <div className="goods">
                                            <img src={goods.img} />
                                            <div className="title-price">
                                                <div>{goods.title}</div>
                                                <div>
                                                    <span>
                                                        {goods.sku_name},x{goods.num}
                                                    </span>
                                                    <span>￥{goods.price}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="order-num">
                            <div>
                                <span>商品金额：</span>
                                <span>￥{item.goods_amount}</span>
                            </div>
                            <div>
                                <span>运费：</span>
                                <span>￥{item.freight_template_fee}</span>
                            </div>
                            <div>
                                <span>优惠：</span>
                                <span
                                    onClick={() => {
                                        getCouponList(item.goods_amount)
                                        setVisible(true)
                                        setCouponIndex(index)
                                    }}
                                >
                                    ￥{discountprice(index)}
                                    &nbsp;&gt;
                                </span>
                            </div>
                            <div>
                                <span>小计：</span>
                                <span style={{ color: '#ff1607' }}>￥{totalpart(item, index)}</span>
                            </div>
                        </div>
                    </div>
                )
            })}
            <Popup
                visible={visible}
                showCloseButton
                onMaskClick={() => {
                    setVisible(false)
                }}
                onClose={() => {
                    setVisible(false)
                }}
                bodyStyle={{ borderRadius: `${toRem(16)} ${toRem(16)} 0 0` }}
            >
                <div style={{ padding: `${toRem(25)} ${toRem(14)}` }}>
                    <div style={{ fontSize: toRem(18) }}>
                        优惠券
                        <span style={{ fontSize: toRem(12), color: '#999999' }}>（不可叠加使用）</span>
                    </div>
                    <div style={{ height: toRem(236), marginTop: toRem(20), overflowY: 'auto' }}>
                        {couponList.map((item, index) => (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    marginBottom: toRem(13),
                                    height: toRem(70),
                                    opacity: item.is_use ? '1' : '0.5',
                                    border: item.id == couponActive[couponIndex].id ? `${toRem(1)} solid #FF5A01` : `${toRem(1)} dashed #999999`,
                                    borderRadius: toRem(5),
                                    position: 'relative',
                                    alignItems: 'center'
                                }}
                                onClick={() => {
                                    if (item.is_use && couponActive.every((v) => v.id != item.id)) {
                                        let _couponActive = [...couponActive]
                                        item.amount = item.type == 3 ? state.shopList[couponIndex].goods_amount * (10 - item.discount) * 0.1 : item.subtract
                                        _couponActive[couponIndex] = item
                                        setCouponActive(_couponActive)
                                    }
                                }}
                            >
                                {item.id == couponActive[couponIndex].id ? (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            width: toRem(21),
                                            height: toRem(12),
                                            background: '#FF5A01',
                                            borderRadius: `0px ${toRem(5)} 0px ${toRem(5)}`,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            top: 0,
                                            right: 0
                                        }}
                                    >
                                        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/Tengchain/shop/checked2.png" alt="" style={{ width: toRem(10), height: toRem(7) }} />
                                    </div>
                                ) : null}

                                <div
                                    style={{
                                        flex: 1,
                                        height: toRem(70),
                                        boxSizing: 'border-box',
                                        padding: toRem(6)
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {item.type == 3 ? (
                                            <div
                                                style={{
                                                    fontSize: toRem(31),
                                                    color: '#FB3C49'
                                                }}
                                            >
                                                {item.discount}
                                                <span
                                                    style={{
                                                        fontSize: toRem(14)
                                                    }}
                                                >
                                                    折
                                                </span>
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    fontSize: toRem(31),
                                                    color: '#FB3C49'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: toRem(14)
                                                    }}
                                                >
                                                    ￥
                                                </span>
                                                {item.subtract * 1}
                                            </div>
                                        )}
                                        <div style={{ marginLeft: toRem(9) }}>
                                            <div
                                                style={{
                                                    fontSize: toRem(12),
                                                    color: '#444444'
                                                }}
                                            >
                                                优惠券
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: toRem(10),
                                                    color: '#444444'
                                                }}
                                            >
                                                {item.type == 2 ? '立减券' : `满${item.consume_full * 1}可使用`}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ fontSize: toRem(10), color: '#999999', marginTop: toRem(5), whiteSpace: 'nowrap' }}>有效期至：{getDateformat(item.end_time, 'y.m.d')}</div>
                                </div>
                                <div style={{ width: ' 0px', height: toRem(51), borderLeft: `${toRem(1)} dashed #999999` }}></div>
                                <div
                                    style={{
                                        width: toRem(50),
                                        height: toRem(70),
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: toRem(12),
                                        color: '#FF5A01',
                                        padding: toRem(10),
                                        boxSizing: 'border-box',
                                        textAlign: 'center'
                                    }}
                                >
                                    {item.id == couponActive[couponIndex].id ? '正在使用' : '使用'}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Popup>
            <div className="message">
                <span>备注：</span>
                <Input style={{ '--text-align': 'right' }} value={state.message} onChange={(e) => setState({ ...state, message: e })}></Input>
            </div>
            <div className="conform-btn">
                <div className="price">
                    <span>共{location.state.goods_total_num}件，合计：</span>
                    <span>￥{getTotalAll()}</span>
                </div>
                <div className="submit" onClick={() => submitOrder()}>
                    提交订单
                </div>
            </div>
        </div>
    )
}

export default ConfirmGoodsOrder
