import React, { useState, useEffect } from 'react'
import style from './index.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { Input, Toast, Mask } from 'antd-mobile'
import axios from '../../../util/axios'
import { uniacid } from '../../../util/config'
function ConfirmOrder () {
    const location = useLocation()
    const navigate = useNavigate()
    let [goodsInfo] = useState(location.state)
    let [state, setState] = useState({
        addressInfo: null,
        user_message: '',
        showSuccess: !1
    })
    let [integral, setIntegral] = useState(0)
    let getDefaultAddress = () => {
        axios
            .post('/JuTwitter/Address/getDefaultAddress', {
                uniacid: uniacid
            })
            .then((res) => {
                if (res.code == 0) {
                    if (res.result) {
                        setState({
                            ...state,
                            addressInfo: res.result
                        })
                    }
                }
            })
    }
    let exchange = async () => {
        try {
            if (!state.addressInfo) throw '请添加收货地址'
            if (integral < goodsInfo.integral_amount) throw '积分不满足兑换'
            axios
                .post('/JuTwitter/IntegralOrder/create', {
                    goods_id: goodsInfo.id,
                    goods_sum: 1,
                    address_id: state.addressInfo.id,
                    user_message: state.user_message
                })
                .then((res) => {
                    if (res.code == 0) {
                        if (res.result.is_need_to_pay) {
                            return axios.post('/JuTwitter/IntegralOrder/pay', {
                                order_id: res.result.order_id
                            })
                        } else {
                            setState({
                                ...state,
                                showSuccess: !0
                            })
                        }
                    } else {
                        Toast.show({
                            content: res.msg
                        })
                    }
                })
                .then((res) => {
                    if (res.code == 0) {
                        window.location.href = res.result.payUrl
                    } else {
                        Toast.show({
                            content: res.msg
                        })
                    }
                })
        } catch (e) {
            Toast.show({
                content: e
            })
        }
    }
    useEffect(() => {
        axios.post('/JuTwitter/Integral/getIntegrale').then((res) => {
            if (res.code == 0) {
                setIntegral(res.result.integral)
            }
        })
        getDefaultAddress()
    }, [])
    return (
        <div className={style.content}>
            <div
                className={style['address-info']}
                onClick={() => {
                    if (state.addressInfo) {
                        navigate('/myAddress', { state: 1 })
                    } else {
                        navigate('/addAddress')
                    }
                }}
            >
                {state.addressInfo ? (
                    <div className={style.left}>
                        <img src={require('../../../image/pointsMall/icon9.png')} alt="" />
                        <div>
                            <p>{state.addressInfo.full_name_address}</p>
                            <p>
                                {state.addressInfo.name} {state.addressInfo.mobile}
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className={style.add}>添加收货地址</div>
                )}
                <div className={style.right}>
                    <img src={require('../../../image/pointsMall/icon8.png')} alt="" />
                </div>
            </div>
            <div className={style['goods-info']}>
                <img className={style['goods-img']} src={goodsInfo.picture[0]} alt="" />
                <div className={style['goods-con']}>
                    <div className={style['goods-title']}>
                        <div>{goodsInfo.name}</div>
                        <div>x1</div>
                    </div>
                    <div className={style['integral-price']}>
                        <span>{goodsInfo.integral_amount}积分</span>
                        {Number(goodsInfo.price) ? <span>+￥{goodsInfo.price}</span> : null}
                    </div>
                </div>
            </div>
            <div className={style['goods-price']}>
                <div className={style.freight}>
                    <span>运费</span>
                    <span>￥{goodsInfo.freight}</span>
                </div>
                <div className={style['integral-price']}>
                    <div>订单合计</div>
                    <div>
                        <span>{goodsInfo.integral_amount}积分</span>
                        {Number(goodsInfo.price) ? <span>+￥{goodsInfo.price}</span> : null}
                    </div>
                </div>
            </div>
            <div className={style.remarks}>
                <span>备注</span>
                <div className={style.put}>
                    <Input style={{ '--text-align': 'right' }} value={state.user_message} onChange={(e) => setState({ ...state, user_message: e })} placeholder="给商家留言~"></Input>
                </div>
            </div>
            <div className={style.btn}>
                <div className={style.integral}>
                    <span>当前可用积分：</span>
                    <span>{integral}</span>
                </div>
                <div className={style.exchange} onClick={() => {
                    exchange()
                    navigate('/confirmOrder', { state: goodsInfo })
                }}>
                    立即兑换
                </div>
            </div>
            <Mask visible={state.showSuccess}>
                <div className={style['success-modal']}>
                    <div className={style['modal-content']}>
                        <div className={style.title}>
                            <img src={require('../../../image/pointsMall/icon11.png')} alt="" />
                            恭喜您兑换成功
                        </div>
                        <div className={style['goods-img']}>
                            <img src={goodsInfo.picture[0]} alt="" />
                        </div>
                        <div className={style.btns}>
                            <div onClick={() => navigate('/integalOrder')}>查看订单</div>
                            <div onClick={() => navigate('/', { replace: true })}>返回首页</div>
                        </div>
                    </div>
                </div>
            </Mask>
        </div>
    )
}

export default ConfirmOrder
